/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { ICmsComponentSeo, ICmsDegeniaReadComponentHeader } from "@teamparallax/api-gateway-rest-api"
import { IMetaProps } from "@teamparallax/react-components"
export const createMeta = (
	seo?: ICmsComponentSeo,
	header?: ICmsDegeniaReadComponentHeader
): IMetaProps => ({
	description: seo?.description || header?.headerText || "",
	keywords: seo?.keywords || "",
	structuredData: seo?.structuredData?.toString(),
	title: seo?.title || header?.headerTitle || "Degenia"
})