import {
	AdvantagesPane,
	CenterChild,
	ContentGenericPane,
	ContentPane,
	CooperationsPane,
	DegeniaButton,
	DegeniaLayout,
	DividedBoxGeneric,
	DividedBoxPartner,
	DividedPane,
	EIcon,
	EPaneColor,
	HeaderContent,
	ICorporationImage,
	IInsurancePropsSsr,
	InsurancePaneSsr,
	JobPane,
	Newsletter,
	Pane,
	PortalLink,
	ProductsTable,
	Separator,
	ShadowScrollBar,
	Small,
	StatsPane,
	TitleWrapper,
	parseStrapiImageUrl,
	useApiGatewayDegeniaRest
} from "../src"
import { Api } from "../next-services/api"
import {
	ECmsDegeniaTextType,
	ICmsDegeniaReadContentTypeLandingpage,
	IMailSendBecomeOurPartner
} from "@teamparallax/api-gateway-rest-api"
import { ENameSpace } from "@teamparallax/client-libraries/lib/i18n/enum"
import {
	GetServerSideProps,
	GetStaticPropsResult
} from "next"
import { IDefaultPageProps } from "../next-services/meta/interface"
import {
	contactProps,
	logoMap,
	retrieveNavigationContent,
	sideMenuProps,
	socialMediaProps
} from "../next-services/meta"
import { createMeta } from "../next-components/util"
import {
	failureToast,
	successToast
} from "@teamparallax/react-components"
import { retrieveInsuranceTree } from "./produkte"
import { useAppConfig } from "@teamparallax/react-components"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useWindowDimensions } from "@teamparallax/client-libraries/lib/hooks/use-window-dimensions"
import React, { FC } from "react"
const productData = {
	body: [
		["Allianz", true, true, true, true, true, false],
		["Alte Leipziger", false, true, true, true, true, true],
		["Alte Oldenburger", true, false, false, false, false, false],
		["Alternativ Invest", false, true, false, false, false, true],
		["Ammerländer", false, false, true, false, false, false],
		["ARAG", true, true, true, true, false, false],
		["Auxilia", false, false, true, true, false, false],
		["AXA", true, true, true, true, true, false],
		["Barmenia", true, true, false, false, true, false],
		["Canada Life", false, true, false, false, false, false],
		["Carl Rieck", false, false, true, false, false, false],
		["Chartis", false, false, true, true, false, false],
		["Concordia", true, false, true, true, true, false],
		["Continentale", true, true, true, true, true, false],
		["CSS", true, false, false, false, false, false],
		["Vigo", true, false, false, false, false, false],
		["DEURAG", false, false, true, false, false, false],
		["Deutscher Ring", true, true, false, false, false, false],
		["DKV", true, false, false, false, false, false],
		["DMB", false, false, true, true, false, false],
		["DOMCURA", false, false, true, false, false, false],
		["Europa", false, true, false, false, false, false],
		["DAS", false, false, true, false, false, false],
		["Generali", true, true, true, true, true, true],
		["Gothaer", true, true, true, true, true, false],
		["Grundeigentümer", false, false, true, false, false, false],
		["Haftpflichtkasse Darmstadt", false, false, true, false, false, false],
		["Hallesche", true, false, false, false, false, false],
		["Hannoversche Leben", false, true, false, false, false, false],
		["Hanse Merkur", true, true, true, false, false, false],
		["HDI-Gerling", false, true, true, true, true, false],
		["Helvetia", false, true, true, true, false, false],
		["Inter", true, true, true, true, false, false],
		["InterRisk", false, true, true, false, false, false],
		["Janitos", true, false, true, true, true, false],
		["KRAVAG", false, false, false, true, true, false],
		["LVtrue87", false, true, false, false, false, false],
		["LKH", true, false, false, false, false, false],
		["Mannheimer", true, false, false, true, false, false],
		["Münchener Verein", true, true, false, false, false, false],
		["Nürnberger", true, true, true, true, true, false],
		["NV Versicherung", false, false, true, false, false, false],
		["Credit Life", false, true, false, false, true, false],
		["R+V", true, true, true, true, true, false],
		["Rechtsschutz Union", false, false, true, true, false, false],
		["ROLAND", false, false, true, true, false, false],
		["SDK", true, false, false, false, false, false],
		["Signal Iduna", true, true, true, true, false, false],
		["Neodigital Versicherung AG", false, false, true, false, false, false],
		["VHV", false, false, true, true, true, false],
		["ERGO", false, false, true, false, false, false],
		["AGILA", false, false, false, false, false, true],
		["Volkswohlbund", false, true, true, false, false, false],
		["Waldenburger", false, false, true, true, false, false],
		["WWK", false, true, false, false, false, false],
		["Zurich", false, true, true, true, true, false],
		["Advocard", false, false, true, false, false, false],
		["Bayerische", true, true, true, false, false, false],
		["BGV", false, false, true, true, true, false],
		["Condor", false, false, true, true, true, false],
		["DA-Direkt", false, false, false, false, true, false],
		["DirectLine", false, false, false, false, true, false],
		["Garanta", false, false, false, false, true, false],
		["Hiscox", false, false, true, true, false, false],
		["Maxpool", false, false, true, false, false, false],
		["NRV", false, false, true, true, false, false],
		["Optima", false, false, false, false, true, false],
		["SwissLife", false, true, true, false, false, false],
		["Schleswiger", false, false, true, false, false, false],
		["Rhion", false, false, true, true, true, false],
		["Württembergische", true, true, true, true, true, false],
		["CHUBB European Group Limited", false, false, true, true, false, false],
		["Hübener", false, false, false, true, false, false],
		["BBKK", true, false, false, false, false, false],
		["Union", true, false, false, false, false, false],
		["degenia", false, false, true, false, false, false],
		["Itzehoer", false, true, true, false, true, false],
		["Uelzener", false, false, true, false, false, false],
		["LBN", false, false, true, false, false, false],
		["Adcuri", false, false, true, false, true, false],
		["Allcura", false, false, true, false, false, false],
		["finanzcheckPRO", false, false, false, false, false, true],
		["Baden-Badener", false, false, true, false, false, false],
		["Basler", false, true, true, true, false, false],
		["Bavaria Direkt", false, false, false, false, true, false],
		["Care Concept", true, false, false, false, false, false],
		["DEMA", false, false, true, false, false, false],
		["DFV Deutsche Familien Versicherung", true, false, false, false, false, false],
		["Dialog", false, true, false, false, false, false],
		["Dual Deutschland", false, false, false, true, false, false],
		["ElviaMondial", true, false, false, false, false, false],
		["GVO", false, false, true, true, false, false],
		["Interlloyd", false, false, true, false, false, false],
		["Medien Versicherung", false, false, true, true, false, false],
		["Monuta", false, true, false, false, false, false],
		["Photovoltaik2", false, false, true, false, false, false],
		["SparkassenDirekt", false, false, false, false, true, false],
		["Stuttgarter", false, false, true, false, false, false],
		["Standard Life", false, true, false, false, false, false],
		["WÜBA", false, false, true, true, false, false],
		["Würzburger", false, false, true, false, false, false],
		["HÄGER", false, false, true, false, false, false],
		["DOCURA", false, false, true, false, false, false],
		["ConceptIF", false, false, true, false, false, false],
		["GIGA-Finanzservice", false, false, true, false, false, false],
		["AMEX", false, false, true, true, false, false],
		["Hamburger Feuerkasse", false, false, true, true, false, false]
	],
	head: [
		"Gesellschaft",
		"Kranken",
		"Leben",
		"Sach",
		"Gewerbe",
		"KFZ",
		"Sonstiges"
	]
}
const offer = (
	<Pane>
		<JobPane
			jobBoxProps={ [{
				button: {
					href: "#",
					title: "Mehr Erfahren"
				},
				description:
	<div>
		Die Privathaftpflicht schützt Sie in
		verschiedenen privaten Lebensbereichen vor
		finanziellen Katastrophen<br/><br/>
		<strong>Ihre Vorteile</strong><br/>
		Abgesichert bis zu 60 Millionen Euro
	</div>,
				icon: EIcon.wineglass,
				title:
	<div>
		Privathaftpflicht T20
	</div>
			}] }
			subTitle="Bitte wählen Sie eine Versicherung"
			title="Unsere Angebote für sie"
		/>
	</Pane>
)
interface IHomePageProps extends IDefaultPageProps, ICmsDegeniaReadContentTypeLandingpage {
	corporationList: ICorporationImage[]
	insurances: IInsurancePropsSsr
}
export const HomePage:FC<IHomePageProps> = ({
	awards,
	carrousel: statsCarousel,
	corporationList,
	coverageConceptsTitle,
	dmuContent,
	faqContent,
	footerListProps,
	genericTexts,
	header,
	insurances,
	linkListProps,
	overviewContent,
	productDiversity,
	stars,
	starsTitle,
	testUs,
	welcomeContent,
	seo,
	...navigation
}) => {
	const dimensions = useWindowDimensions()
	const {
		strapiUrl
	} = useAppConfig()
	const {
		t
	} = useTranslation(ENameSpace.common)
	const {
		mailApi
	} = useApiGatewayDegeniaRest()
	const newsLetterPrivacyStatement = `Ja, ich habe die [Datenschutzerklärung](/datenschutz-degenia)zur Kenntnis
	genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben
	und gespeichert werden. Meine Daten werden dabei nur streng zweckgebunden zur Bearbeitung und
	Beantwortung meiner Anfrage benutzt. Mit dem Absenden des Kontaktformulars erkläre ich mich mit
	der Verarbeitung einverstanden.`
	const advantagesPaneContent = stars.map(
		(starContent) => ({
			advantagesList: starContent.content
				.replace(/\n|\t/g, "")
				.split("*")
				.filter((s) => s !== "")
				.map((s) => s.trim()),
			title: starContent.title
		})
	)
	const statsPaneContent = statsCarousel.map(
		({
			content,
			icon,
			title
		}) => ({
			icon: EIcon[icon as EIcon],
			text: title,
			value: content
		})
	)
	const router = useRouter()
	return (
		<DegeniaLayout
			{ ...navigation }
			awards={ awards }
			contactProps={ contactProps }
			footerListProps={ footerListProps }
			genericTexts={ genericTexts }
			hasDmu
			headerContent={ (
				<HeaderContent
					button={ header.buttonUrl
						? {
							href: header.buttonUrl,
							title: header.buttonText
						}
						: undefined }
					icon={ header.icon as unknown as EIcon }
					subTitle={ header.headerText }
					title={ header.headerTitle }
				/>
			) }
			imageUrl={ parseStrapiImageUrl(strapiUrl, header.headerImage ?? header.headerImageUrl) }
			linkListProps={ linkListProps }
			logoMap={ logoMap }
			meta={ createMeta(seo, header) }
			sideMenuProps={ {
				scrollHeight: dimensions.height / 2,
				sideMenuBoxProps: sideMenuProps
			} }
			socialMediaProps={ socialMediaProps }
		>
			<Pane>
				<ContentPane
					button={ header.buttonUrl
						? {
							href: header.buttonUrl,
							title: header.buttonText
						}
						: undefined }
					content={ welcomeContent.content }
					hasTwoColumns={ welcomeContent.textType
						=== ECmsDegeniaTextType.TwoColumns }
					isMarkdownContent
					subTitle={ welcomeContent.subTitle }
					title={ welcomeContent.title }
				/>
			</Pane>
			<Pane
				paneColor={ EPaneColor.lightteal }
			>
				<StatsPane
					boxProps={ statsPaneContent }
				/>
			</Pane>
			<Pane>
				<ContentPane
					content={ dmuContent.content }
					hasTwoColumns={ dmuContent.textType
						=== ECmsDegeniaTextType.TwoColumns }
					isMarkdownContent
					subTitle={ dmuContent.subTitle }
					title={ dmuContent.title }
				/>
			</Pane>
			<Pane
				paneColor={ EPaneColor.lightteal }
			>
				<CooperationsPane
					corporationList={ corporationList }
					title={ coverageConceptsTitle.title }
				/>
			</Pane>
			<Pane
				paneColor={ EPaneColor.white }
			>
				<AdvantagesPane
					advantagesBoxProps={ advantagesPaneContent }
					title={ starsTitle }
				/>
			</Pane>
			<InsurancePaneSsr
				{ ...insurances }
				openInsuranceId={ router.query.category as string }
			/>
			<Pane>
				<ContentGenericPane>
					<ContentPane
						content={ productDiversity.content }
						subTitle={ productDiversity.title }
					/>
				</ContentGenericPane>
				<br/>
				<br/>
				<ShadowScrollBar
					height={ 540 }
				>
					<ProductsTable data={ productData }/>
				</ShadowScrollBar>
				<br/>
				<br/>
				<DegeniaButton
					href="#partner-werden"
					isInverted
					style={ {
						float: "right"
					} }
					title="Jetzt Partner werden"
				/>
			</Pane>
			<Separator/>
			<Pane>
				<ContentPane
					content={ overviewContent.content }
					hasTwoColumns={ overviewContent.textType === ECmsDegeniaTextType.TwoColumns }
					isMarkdownContent
					subTitle={ overviewContent.subTitle }
					title={ overviewContent.title }
				/>
			</Pane>
			<Pane
				hasNoPadding
				id="partner-werden"
				paneColor={ EPaneColor.greyblue }
			>
				<DividedPane
					firstChildTitle={ testUs.title }
					secondChildTitle="Kontakt aufnehmen"
				>
					<DividedBoxGeneric
						icon={ EIcon[ testUs.icon as EIcon ] }
					>
						<strong>
							<Small>
								Testen Sie unser Extranet mit allen Tools, Rechnern und Services.
								Jetzt mit dem Testzugang einloggen:
							</Small>
							<PortalLink
								link="https://www.mydegenia.de"
								text="mydegenia.de"
							/>
							<Small>
								Benutzername: demo@degenia.de<br/>Passwort: demo
							</Small>
						</strong>
					</DividedBoxGeneric>
					<DividedBoxPartner
						buttonText="Jetzt Partner werden"
						icon={ EIcon.rocket }
						onSubmit={ async (formData: IMailSendBecomeOurPartner): Promise<void> => {
							try {
								await mailApi.sendDegeniaBecomeOurPartner(formData)
								successToast("Partnerantrag erfolgreich verschickt")
							}
							catch (e) {
								failureToast("Es gab einen Fehler beim Versenden des Partnerantrags")
								// eslint-disable-next-line no-console
								console.log(e)
							}
						} }
					/>
				</DividedPane>
			</Pane>
			<Pane>
				<ContentPane
					content={ faqContent.content }
					hasTwoColumns={ faqContent.textType === ECmsDegeniaTextType.TwoColumns }
					isMarkdownContent
					subTitle={ faqContent.subTitle }
					title={ faqContent.title }
				/>
			</Pane>
			<Pane
				paneColor={ EPaneColor.greyblue }
			>
				<CenterChild>
					<TitleWrapper
						isInverted
						isTitleSmall
						title="Jetzt zum Newsletter anmelden"
					/>
					<Newsletter
						buttonText="Anmelden"
						icon={ EIcon.newsletter }
						onClick={ (): void => {alert("Clicked on Newsletter")} }
						privacyStatement={ newsLetterPrivacyStatement }
					/>
				</CenterChild>
			</Pane>
		</DegeniaLayout>
	)
}
export default HomePage
const api = new Api()
export const getServerSideProps: GetServerSideProps = async ({
	params, locale
}): Promise<GetStaticPropsResult<IHomePageProps>> => {
	try {
		const navigationContent = await retrieveNavigationContent(locale)
		const {
			data: companyLogos
		} = await api.cms.getAllDegeniaCompanyLogos()
		const corporationList = companyLogos.map((logo) => ({
			alt: logo.alt ?? logo.title,
			href: logo.url ?? null,
			imageUrl: logo.image ?? logo.imageUrl,
			priority: logo.priority ?? null
		})) as ICorporationImage[]
		const {
			data: landingPage
		} = await api.cms.getDegeniaLandingpage()
		return {
			props: {
				corporationList,
				insurances: await retrieveInsuranceTree(),
				...navigationContent,
				...landingPage
			}
		}
	}
	catch (e) {
		console.warn("Api Gateway Error:", e)
		return {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			notFound: true
		}
	}
}